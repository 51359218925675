import defaultLoader from '../../default/features/loader'

// document.addEventListener("DOMContentLoaded",function(){

//NOTE: A better selector would be [data-features~="name"] which allows multiple features like data-features="feature1 feature2 feature3"]
const run = async () => {

    // always load default features first,
    // TODO: rework package loaders so we we can overrule them below if needed
    await defaultLoader()

    const lazyLoaders = [
        {
            selector: '[data-features~="about-info"]', loader: () => import(/* webpackChunkName: "feature-about-info" */ './about-info')
        }, {
            selector: '[data-features~="advisors-section"]', loader: () => import(/* webpackChunkName: "feature-advisors-section" */ './advisors-section')
        }, {
            selector: '[data-features~="autoscroll"], .autoscroll', loader: () => import(/* webpackChunkName: "feature-autoscroll" */ './autoscroll')
        }, {
            selector: '[data-features~="card-house-list"]', loader: () => import(/* webpackChunkName: "feature-card-house-list" */ './card-house-list')
        }, {
            selector: '[data-features~="card-privacy"]', loader: () => import(/* webpackChunkName: "feature-card-privacy" */ './card-privacy')
        }, {
            selector: '[data-features~="conditual-logic"], .conditual-logic', loader: () => import(/* webpackChunkName: "feature-conditual-logic" */ './conditual-logic')
        }, {
            selector: '[data-features~="countdown"], .countdown', loader: () => import(/* webpackChunkName: "feature-countdown" */ './countdown')
        }, {
            selector: '[data-features~="cta-house"]', loader: () => import(/* webpackChunkName: "feature-cta-house" */ './cta-house')
        }, {
            selector: '[data-features~="employee-slider"], .employee-slider', loader: () => import(/* webpackChunkName: "feature-employee-slider" */ './employeeSlider')
        }, {
            selector: '[data-features~="faq-section"]', loader: () => import(/* webpackChunkName: "feature-faq-section" */ './faq-section')
        }, {
            selector: '[data-features~="favorite"], .favorite-feature', loader: () => import(/* webpackChunkName: "feature-favorite" */ './favorite')
        }, {
            selector: '[data-features~="filter-popup"]', loader: () => import(/* webpackChunkName: "feature-filter-popup" */ './filter-popup')
        }, {
            selector: '[data-features~="filters"]', loader: () => import(/* webpackChunkName: "feature-filters" */ './filters')
        }, {
            selector: '[data-features~="footer"]', loader: () => import(/* webpackChunkName: "feature-footer" */ './footer')
        }, {
            selector: '[data-features~="form"], .form', loader: () => import(/* webpackChunkName: "feature-form" */ './form')
        }, {
            selector: '[data-features~="form-toggle"]', loader: () => import(/* webpackChunkName: "feature-form-toggle" */ './form-toggle')
        }, {
            selector: '[data-features~="gallery-popup"]', loader: () => import(/* webpackChunkName: "feature-gallery-popup" */ './gallery-popup')
        }, {
            selector: '[data-features~="header"]', loader: () => import(/* webpackChunkName: "feature-header" */ './header')
        }, {
            selector: '[data-features~="services"]', loader: () => import(/* webpackChunkName: "feature-services" */ './heroServices')
        }, {
            selector: '[data-features~="house-filter"]', loader: () => import(/* webpackChunkName: "feature-house-filter" */ './house-filter')
        }, {
            selector: '[data-features~="house-info"]', loader: () => import(/* webpackChunkName: "feature-house-info" */ './house-info')
        }, {
            selector: '[data-features~="house-location"]', loader: () => import(/* webpackChunkName: "feature-house-location" */ './house-location')
        }, {
            selector: '[data-features~="house-map"]', loader: () => import(/* webpackChunkName: "feature-house-map" */ './house-map')
        }, {
            selector: '[data-features~="house-media"]', loader: () => import(/* webpackChunkName: "feature-house-media" */ './house-media')
        }, {
            selector: '[data-features~="house-object"]', loader: () => import(/* webpackChunkName: "feature-house-object" */ './house-object')
        }, {
            selector: '[data-features~="housingneeds"], .housingneeds', loader: () => import(/* webpackChunkName: "feature-housingneeds" */ './housingneeds')
        }, {
            selector: '[data-features~="landing-banner"]', loader: () => import(/* webpackChunkName: "feature-landing-banner" */ './landing-banner')
        }, {
            selector: '[data-features~="livingwish-account"]', loader: () => import(/* webpackChunkName: "feature-livingwish-account" */ './livingwish-account')
        }, {
            selector: '[data-features~="livingwish-popup"]', loader: () => import(/* webpackChunkName: "feature-livingwish-popup" */ './livingwish-popup')
        }, {
            selector: '[data-features~="login-popup"]', loader: () => import(/* webpackChunkName: "feature-login-popup" */ './login-popup')
        }, {
            selector: '[data-features~="maps"], [data-feature="maps"]', loader: () => import(/* webpackChunkName: "feature-maps" */ './maps')
        }, {
            selector: '[data-features~="news-filters"]', loader: () => import(/* webpackChunkName: "feature-news-filters" */ './newsFilters')
        }, {
            selector: '[data-features~="news-list"]', loader: () => import(/* webpackChunkName: "feature-news-list" */ './newsList')
        }, {
            selector: '[data-features~="news-ordering"]', loader: () => import(/* webpackChunkName: "feature-news-order" */ './newsOrder')
        }, {
            selector: '[data-features~="onboarding-popup"]', loader: () => import(/* webpackChunkName: "feature-onboarding-popup" */ './onboarding-popup')
        }, {
            selector: '[data-features~="pageareas"], .pageareas', loader: () => import(/* webpackChunkName: "feature-pageareas" */ './pageareas')
        }, {
            selector: '[data-features~="pricelist"], .pricelist', loader: () => import(/* webpackChunkName: "feature-pricelist" */ './pricelist')
        }, {
            selector: '[data-features~="project-slider"], .project-slider-feature', loader: () => import(/* webpackChunkName: "feature-project-slider" */ './project-slider')
        }, {
            selector: '[data-features~="customer-reviews-slider"]', loader: () => import(/* webpackChunkName: "feature-customer-review-slider" */ './customer-reviews-slider')
        }, {
            selector: '[data-features~="projectareas"], .projectareas', loader: () => import(/* webpackChunkName: "feature-projectareas" */ './projectareas')
        }, {
            selector: '[data-features~="project-filters"]', loader: () => import(/* webpackChunkName: "feature-project-filters" */ './projectFilters')
        }, {
            selector: '[data-features~="project-list"]', loader: () => import(/* webpackChunkName: "feature-project-list" */ './projectList')
        }, {
            selector: '[data-features~="project-ordering"]', loader: () => import(/* webpackChunkName: "feature-project-order" */ './projectOrder')
        }, {
            selector: '[data-features~="projectpricelistinteractive"], .projectpricelistinteractive', loader: () => import('./projectpricelistinteractive')
        }, {
            selector: '[data-features~="projectsalestatus"], .projectsalestatus', loader: () => import(/* webpackChunkName: "feature-projectsalestatus" */ './projectsalestatus')
        }, {
            selector: '[data-features~="region-map"]', loader: () => import(/* webpackChunkName: "feature-region-map" */ './region-map')
        }, {
            selector: '[data-features~="region-realtor"]', loader: () => import(/* webpackChunkName: "feature-region-realtor" */ './region-realtor')
        }, {
            selector: '[data-features~="rent-calculator"], .rent-calculator', loader: () => import(/* webpackChunkName: "feature-rent-calculator" */ './rentCalculator')
        }, {
            selector: '[data-features~="roadmap"]', loader: () => import(/* webpackChunkName: "feature-roadmap" */ './roadmap')
        }, {
            selector: '[data-features~="search"]', loader: () => import(/* webpackChunkName: "feature-search" */ './search')
        }, {
            selector: '[data-features~="search-news"]', loader: () => import(/* webpackChunkName: "feature-search-news" */ './searchNews')
        }, {
            selector: '[data-features~="services"]', loader: () => import(/* webpackChunkName: "feature-services" */ './heroServices')
        }, {
            selector: '[data-features~="show-once"], .show-once', loader: () => import(/* webpackChunkName: "feature-show-once" */ './showOnce')
        }, {
            selector: '[data-features~="smallmedia"], .smallmedia', loader: () => import(/* webpackChunkName: "feature-smallmedia" */ './smallmedia')
        }, {
            selector: '[data-features~="social-login-apple"]', loader: () => import(/* webpackChunkName: "feature-social-login-apple" */ './social-login-apple')
        }, {
            selector: '[data-features~="social-login-facebook"]', loader: () => import(/* webpackChunkName: "feature-social-login-facebook" */ './social-login-facebook')
        }, {
            selector: '[data-features~="social-login-google"]', loader: () => import(/* webpackChunkName: "feature-social-login-google" */ './social-login-google')
        }, {
            selector: '[data-features~="solutions-slider"]', loader: () => import(/* webpackChunkName: "feature-solutions-slider" */ './solutions-slider')
        }, {
            selector: '[data-features~="sort-popup"]', loader: () => import(/* webpackChunkName: "feature-sort-popup" */ './sort-popup')
        }, {
            selector: '[data-features~="specialists-form"]', loader: () => import(/* webpackChunkName: "feature-specialists-form" */ './specialists-form')
        }, {
            selector: '[data-features~="specialists-slider"]', loader: () => import(/* webpackChunkName: "feature-specialists-slider" */ './specialists-slider')
        }, {
            selector: '[data-features~="specialistsfinder"]', loader: () => import(/* webpackChunkName: "feature-specialists-finder" */ './specialistsFinder')
        }, {
            selector: '[data-features~="specialistsmap"]', loader: () => import(/* webpackChunkName: "feature-specialists-map" */ './specialistsMap')
        }, {
            selector: '[data-features~="story-slider"]', loader: () => import(/* webpackChunkName: "feature-story-slider" */ './story-slider')
        }, {
            selector: '[data-features~="survey"], .survey', loader: () => import(/* webpackChunkName: "feature-survey" */ './survey')
        }, {
            selector: '[data-features~="usp-info"]', loader: () => import(/* webpackChunkName: "feature-usp-info" */ './usp-info')
        }, {
            selector: '[data-features~="usp-slider"]', loader: () => import(/* webpackChunkName: "feature-usp-slider" */ './usp-slider')
        }, {
            selector: '[data-features~="developer-slider"]', loader: () => import(/* webpackChunkName: "feature-developer-slider" */ './developer-slider')
        }, {
            selector: 'input[data-ajax-submit-url]', loader: () => import('./submitinajax')
        }, {
            selector: '[data-features~="unsubscribe"]', loader: () => import('./unsubscribe')
        }, {
            selector: '[data-features~="sidebar"]', loader: () => import('./sidebar')
        },
    ]

    for(const {selector, loader} of lazyLoaders ) {
        const selectorElements = document.querySelectorAll(selector);

        if (selectorElements.length > 0) {
            for (const element of selectorElements) {

                const feature = await loader()

                if (typeof feature.default === "function") {
                    feature.default({element})
                }
            }
        }
    }
}

run();



