import 'assets/styles/app.scss';
import 'bootstrap';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'


// keep this listener to make sure all template blocks are functioning properly
const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					`components/${componentName}/${componentName}.js`
					);

				component.default();
			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}
} );
