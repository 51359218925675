export default async () => {
    const lazyLoaders = [
        {
            selector: '[data-features~="consumer-account"]', loader: () => import(/* webpackChunkName: "feature-account" */ './consumer-account')
        },{
            selector: '[data-features~="consumer-account"]', loader: () => import(/* webpackChunkName: "feature-account" */ './consumer-account/project-slider')
        },{
            selector: '[data-features~="consumer-account"]', loader: () => import(/* webpackChunkName: "feature-account" */ './consumer-account/usp-info')
        }, {
            selector: '.account-lot-preferences', loader: () => import(/* webpackChunkName: "feature-account" */ './consumer-account/lot-preferences')
        }, {
            selector: '[data-features~="account-privacy"], .account-privacy', loader: () => import(/* webpackChunkName: "feature-account-privacy" */ './consumer-account/accountPrivacy.js') /* todo: move into account.js file */
        },
    ]

    for(const {selector, loader} of lazyLoaders ) {
        const selectorElements = document.querySelectorAll(selector);

        if (selectorElements.length > 0) {
            for (const element of selectorElements) {

                const feature = await loader()

                if (typeof feature.default === "function") {
                    feature.default({element})
                }
            }
        }
    }
}


