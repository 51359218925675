// load block loader
import '../features/loader';

// Ignore the warnings. It's an alias to the domain set by webpack.
import 'domainScript'; // import domain script

// Import the scripts
import '../assets/scripts/app';

/**
 * style { width: attr(data-percentage %} is still experimental and is not supported. Fix with js.
 */
for (const cardHouseBar of document.querySelectorAll('.card-house__bar-inner')) {
    const percentage = cardHouseBar.dataset.percentage
    if (percentage) {
        cardHouseBar.style.width = percentage + '%'
    }
}
